import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, from, concatMap, toArray, tap, EMPTY  } from 'rxjs';
import { previousDay } from "date-fns";
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StockDataService {

  //private apiUrl = `http://localhost:3000/stockService`;
  private apiUrl = `https://www.lambdafin.com/stockService`;        

  lastWrkDay: string = this.setLastWorkdayAsString();
  FiveYearsAgo: string = this.setXYearsAgo(5);
  OneYearAgo: string = this.setXYearsAgo(1);
  headers = new HttpHeaders({ 'Cache-Control': 'no-cache' });
  isBrowser:boolean =false;
  news: any = {};

  earnEvents$ = new BehaviorSubject<any[]>([]);
  heatMapSp500$ = new BehaviorSubject<any[]>([]);
  heatMapNdq$ = new BehaviorSubject<any[]>([]);
  heatMapDow$ = new BehaviorSubject<any[]>([]);
  gainers$ = new BehaviorSubject<any[]>([]);
  losers$ = new BehaviorSubject<any[]>([]);
  actives$ = new BehaviorSubject<any[]>([]);
  stockDataSubject$ = new BehaviorSubject([]);


  keyDescriptions: { [key: string]: string } = {
    'price': 'Price',
    'volume': 'Volume',
    'dividend': 'Dividend',
    'sector': 'Sector',
    'industry': 'Industry',
    'revenuePerShare': 'Revenue Per Share',
    'netIncomePerShare': 'Net Income Per Share',
    'operatingCashFlowPerShare': 'Operating Cash Flow Per Share',
    'freeCashFlowPerShare': 'Free Cash Flow Per Share',
    'cashPerShare': 'Cash Per Share',
    'bookValuePerShare': 'Book Value Per Share',
    'tangibleBookValuePerShare': 'Tangible Book Value Per Share',
    'shareholdersEquityPerShare': 'Shareholders Equity Per Share',
    'interestDebtPerShare': 'Interest Debt Per Share',
    'marketCap': 'Market Cap',
    'enterpriseValue': 'Enterprise Value',
    'peRatio': 'P/E Ratio',
    'priceToSalesRatio': 'Price to Sales Ratio',
    'pocfratio': 'Price to Operating Cash Flow Ratio',
    'pfcfRatio': 'Price to Free Cash Flow Ratio',
    'pbRatio': 'P/B Ratio',
    'ptbRatio': 'Price to Tangible Book Value Ratio',
    'evToSales': 'EV to Sales',
    'enterpriseValueOverEBITDA': 'Enterprise Value Over EBITDA',
    'evToOperatingCashFlow': 'EV to Operating Cash Flow',
    'evToFreeCashFlow': 'EV to Free Cash Flow',
    'earningsYield': 'Earnings Yield',
    'freeCashFlowYield': 'Free Cash Flow Yield',
    'debtToEquity': 'Debt to Equity',
    'debtToAssets': 'Debt to Assets',
    'netDebtToEBITDA': 'Net Debt to EBITDA',
    'currentRatio': 'Current Ratio',
    'interestCoverage': 'Interest Coverage',
    'incomeQuality': 'Income Quality',
    'dividendYield': 'Dividend Yield',
    'payoutRatio': 'Payout Ratio',
    'salesGeneralAndAdministrativeToRevenue': 'Sales General and Administrative to Revenue',
    'researchAndDdevelopementToRevenue': 'Research and Development to Revenue',
    'intangiblesToTotalAssets': 'Intangibles to Total Assets',
    'capexToOperatingCashFlow': 'Capex to Operating Cash Flow',
    'capexToRevenue': 'Capex to Revenue',
    'capexToDepreciation': 'Capex to Depreciation',
    'stockBasedCompensationToRevenue': 'Stock Based Compensation to Revenue',
    'grahamNumber': 'Graham Number',
    'roic': 'Return on Invested Capital (ROIC)',
    'returnOnTangibleAssets': 'Return on Tangible Assets',
    'grahamNetNet': 'Graham Net-Net',
    'workingCapital': 'Working Capital',
    'tangibleAssetValue': 'Tangible Asset Value',
    'netCurrentAssetValue': 'Net Current Asset Value',
    'investedCapital': 'Invested Capital',
    'averageReceivables': 'Average Receivables',
    'averagePayables': 'Average Payables',
    'averageInventory': 'Average Inventory',
    'daysSalesOutstanding': 'Days Sales Outstanding',
    'daysPayablesOutstanding': 'Days Payables Outstanding',
    'daysOfInventoryOnHand': 'Days of Inventory on Hand',
    'receivablesTurnover': 'Receivables Turnover',
    'payablesTurnover': 'Payables Turnover',
    'inventoryTurnover': 'Inventory Turnover',
    'roe': 'Return on Equity (ROE)',
    'capexPerShare': 'Capex Per Share'
  };

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: object,) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      this.news = (window as any).__SERVER_CACHE__.news;
      (window as any).__SERVER_CACHE__.news = {};
    }
  
    this.fetchDataWithCache('sp500Heatmap', () => this.getHeatmap("sp500")).subscribe((data: any[]) => {
      this.heatMapSp500$.next(data);
    });
  
    this.fetchDataWithCache('ndqHeatmap', () => this.getHeatmap("ndq")).subscribe((data: any[]) => {
      this.heatMapNdq$.next(data);
    });
  
    this.fetchDataWithCache('dowHeatmap', () => this.getHeatmap("dow")).subscribe((data: any[]) => {
      this.heatMapDow$.next(data);
    });

    this.fetchDataWithCache('gainers', () => this.gainers()).subscribe(data => {
      this.gainers$.next(data);
    });

    this.fetchDataWithCache('losers', () => this.losers()).subscribe(data => {
      this.losers$.next(data);
    });

    this.fetchDataWithCache('mostActive', () => this.mostActive()).subscribe(data => {
      this.actives$.next(data);
    });
    // if (this.isBrowser) {
    //   (window as any).__SERVER_CACHE__.actives = {};
    // }
  }

  private fetchDataWithCache<T>(key: string, fetchFn: () => Observable<T>): Observable<T> {
    if (!this.isBrowser) {
      console.log(`SSR environment detected; skipping cache for ${key}`);
      return EMPTY ;
    }

    const cache = (window as any).__SERVER_CACHE__ || ((window as any).__SERVER_CACHE__ = {});
  
    if (cache[key]) {
      console.log(`Using cached data for ${key}`);
      const data = cache[key];
      cache[key] = {};
      return new BehaviorSubject(data).asObservable();
    }
  
    return fetchFn().pipe(
      tap(data => {
        console.log(`Updating cache for ${key}`);
        cache[key] = data; // Store the fetched data in the cache
      })
    );
  }

  // pollEarningsEvents(): void {
  //   this.getEarningsEvents().subscribe(
  //     data => this.earnEvents$.next(data),
  //     error => console.error('Error fetching earnings events:', error)
  //   );
  // }

  pollHeatmap(index: string): void {
    this.getHeatmap(index).subscribe(
      data => {
        switch (index) {
          case 'sp500':
            this.heatMapSp500$.next(data);
            break;
          case 'ndq':
            this.heatMapNdq$.next(data);
            break;
          case 'dow':
            this.heatMapDow$.next(data);
            break;
        }
      },
      error => console.error(`Error fetching heatmap data for ${index}:`, error)
    );
  }

  private executeBatchesSequentially(batchedRequests: Observable<any[]>[]): Observable<any[]> {
    return from(batchedRequests).pipe(
      concatMap((batch) => batch), // Process one batch at a time
      toArray() // Collect all results into a single array
    );
  }

  // Utility methods for chunking and identifying index data
  private chunkArray(arr: any[], size: number): any[] {
    const result: any[] = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  }

  pollGainers(): void {
    if (this.isValidPollTime()) {
      this.gainers().subscribe(
        data => this.gainers$.next(data),
        error => console.error('Error fetching gainers:', error)
      );
    }
  }

  pollLosers(): void {
    if (this.isValidPollTime()) {
      this.losers().subscribe(
        data => this.losers$.next(data),
        error => console.error('Error fetching losers:', error)
      );
    }
  }

  pollMostActive(): void {
    if (this.isValidPollTime()) {
      this.mostActive().subscribe(
        data => this.actives$.next(data),
        error => console.error('Error fetching most active:', error)
      );
    }
  }

  isValidPollTime(): boolean {
    // Get the current time in Eastern Time (ET)
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      weekday: 'long'
    });

    // Get the formatted parts
    const formattedTime = formatter.formatToParts(now);

    const dayOfWeek = parseInt(formattedTime.find(part => part.type === 'weekday')?.value || '0'); // Sunday is 1, Saturday is 7
    const hour = parseInt(formattedTime.find(part => part.type === 'hour')?.value || '0');
    const minutes = parseInt(formattedTime.find(part => part.type === 'minute')?.value || '0');

    // Adjust weekday to match JavaScript's convention (Sunday is 0, Saturday is 6)
    const jsDayOfWeek = dayOfWeek === 7 ? 6 : dayOfWeek - 1;  // Convert to Sunday as 0

    // Only allow polling on weekdays (Mon-Fri) between 9:30 AM and 4 PM in ET
    const isWeekday = jsDayOfWeek >= 1 && jsDayOfWeek <= 5;
    const isAfterNineThirty = hour > 9 || (hour === 9 && minutes >= 30);
    const isBeforeFourPm = hour < 20;

    return isWeekday && isAfterNineThirty && isBeforeFourPm;
  }

  // Utility function to sleep
  private sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  setXYearsAgo(x: number): string {
    const fiveYearsAgo = new Date();
    const today = new Date();
    let lastWorkday = new Date();
    fiveYearsAgo.setFullYear(today.getFullYear() - x);

    // while (lastWorkday.getDay() === 0 || lastWorkday.getDay() === 6) {
    //   lastWorkday.setDate(lastWorkday.getDate() - 1);
    // }

    // Convert lastWorkday to 'YYYY-MM-DD' string format
    const lastWorkdayString = fiveYearsAgo.toISOString().split('T')[0];
    return lastWorkdayString;
  }

  setLastWorkdayAsString(): string {
    const today = new Date();
    let lastWorkday = today;
    lastWorkday.setHours(9.5);
    if (today.getDay() === 6 || today.getDay() === 0) {
      lastWorkday = previousDay(today, 5);
    }

    const lastWorkdayString = lastWorkday.toISOString().split('T')[0];
    return lastWorkdayString;
  }



  getSPYConst(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getSPYConst`, { headers: this.headers });
  }

  // Fetch Nasdaq 100 symbols
  getNDQConst(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getNDQConst`, { headers: this.headers });
  }

  // Fetch S&P 1500 symbols
  getSP1500Const(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getSP1500Const`, { headers: this.headers });
  }

  getStockLists(title:string):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/actives/${title}`, { headers: this.headers });
  }

  getMarketCap(ticker: string): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/getMarketCap/${ticker}`, { headers: this.headers }).toPromise();
  }

  getProfile(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getProfile/${ticker}`, { headers: this.headers });
  }

  getKeyMetrics(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getKeyMetrics/${ticker}`, { headers: this.headers });
  }

  getV2KeyMetrics(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/v2GetKeyMetrics/${ticker}`, { headers: this.headers });
  }

  getStockData(ticker: string, from: string = this.FiveYearsAgo, to: string = this.lastWrkDay): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getStockData/${ticker}?from=${from}&to=${to}`, { headers: this.headers });
  }

  getIntradayStockData(ticker: string, timeframe: string = '1min', from: string = this.lastWrkDay, to: string = this.lastWrkDay): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getIntradayStockData/${ticker}?timeframe=${timeframe}&from=${from}&to=${to}`, { headers: this.headers });
  }

  getIncomeStatement(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getIncomeStatement/${ticker}`, { headers: this.headers });
  }

  getBalanceStatement(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getBalanceStatement/${ticker}`, { headers: this.headers });
  }

  getCashStatement(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getCashStatement/${ticker}`, { headers: this.headers });
  }

  getFilings(ticker: string, type?: string): Observable<any> {
    const urlWithoutType = `${this.apiUrl}/getFilings/${ticker}`
    const urlWithType = type ? `${urlWithoutType}?type=${type}` : urlWithoutType;
    return this.http.get<any>(urlWithType, { headers: this.headers });
  }

  getAINews(symbol?: string, type?: string, pos?:number): Observable<any> {
    const apiUrlBase = `${this.apiUrl}/getAINews1`;
    let apiUrl = apiUrlBase;
    

    if (symbol) {
        apiUrl = `${apiUrlBase}/${type}/${pos}/${symbol}`;
    } else if (type) {
        apiUrl = `${apiUrlBase}/${type}/${pos}`;
    }

    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getNewsByDateAndHeadline(publishedDate: string, headline: string): Observable<any> {
    const encodedHeadline = encodeURIComponent(headline);
    return this.http.get<any>(`${this.apiUrl}/getAINews/${publishedDate}/${encodedHeadline}`);
  }

  indexToGoogle(body: any): Observable<any> {
    const googleIndexAPIUrl = 'https://indexing.googleapis.com/v3/urlNotifications:publish';
    return this.http.post<any>(googleIndexAPIUrl, body);
  }


  getStockNews(ticker?: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getStockNews`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  getCryptoNews(ticker?: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getCryptoNews`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  getForexNews(ticker?: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getForexNews`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  getNews(): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getNews`;
    return this.http.get<any>(apiUrlWithoutTicker, { headers: this.headers });
  }

  getHeatmap(index: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/heatmap/${index}`, { headers: this.headers });
  }

  getAnalystE(ticker: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getAnalystE/${ticker}`, { headers: this.headers });
  }

  filterStocks(
    filters: any,
    marketCapOperator?: string,
    marketCapValue?: string,
    priceOperator?: string,
    priceValue?: string,
    betaOperator?: string,
    betaValue?: string,
    volumeOperator?: string,
    volumeValue?: string,
    dividendOperator?: string,
    dividendValue?: string,
    sector?: string,
    industry?: string
  ): Observable<any> {
    // Define your filtering criteria
    const params = new HttpParams()
      .set('filters', JSON.stringify(filters))
      .set('marketCapOperator', marketCapOperator !== undefined ? marketCapOperator : '')
      .set('marketCapValue', marketCapValue !== undefined ? marketCapValue : '')
      .set('priceOperator', priceOperator !== undefined ? priceOperator : '')
      .set('priceValue', priceValue !== undefined ? priceValue : '')
      .set('betaOperator', betaOperator !== undefined ? betaOperator : '')
      .set('betaValue', betaValue !== undefined ? betaValue : '')
      .set('volumeOperator', volumeOperator !== undefined ? volumeOperator : '')
      .set('volumeValue', volumeValue !== undefined ? volumeValue : '')
      .set('dividendOperator', dividendOperator !== undefined ? dividendOperator : '')
      .set('dividendValue', dividendValue !== undefined ? dividendValue : '')
      .set('sector', sector !== undefined ? sector : '')
      .set('industry', industry !== undefined ? industry : '');


    (filters);
    // Make the API call with the defined parameters
    return this.http.get<any>(`${this.apiUrl}/filter`, { params, headers: this.headers });
  }

  getEconomicEvents(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/calendar/economic`, { headers: this.headers });
  }

  getEarningsEvents(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/calendar/earnings`, { headers: this.headers });
  }

  mostActive(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/mostActive`, { headers: this.headers });
  }

  gainers(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/gainers`, { headers: this.headers });
  }

  losers(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/losers`, { headers: this.headers });
  }

  getStockRealTimeData(ticker?: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getRealTimeData`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  getStockRealTimeMetrics(ticker: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getRealTimeMetrics`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  getCryptoData(ticker?: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getCryptoData`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  getForexData(ticker?: string): Observable<any> {
    const apiUrlWithoutTicker = `${this.apiUrl}/getForexData`;
    const apiUrlWithTicker = ticker ? `${apiUrlWithoutTicker}/${ticker}` : apiUrlWithoutTicker;
    return this.http.get<any>(apiUrlWithTicker, { headers: this.headers });
  }

  // Function to get the stock list
  stockList(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/stockList`, { headers: this.headers });
  }

  // Function to get the crypto list
  cryptoList(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/cryptoList`, { headers: this.headers });
  }

  // Function to get the forex list
  forexList(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/forexList`, { headers: this.headers });
  }

  // Function to get stock peers for a specific ticker
  stockPeers(ticker: string): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/stockPeers/${ticker}`, { headers: this.headers });
  }

  // Function to get price target for a specific ticker
  priceTarget(ticker: string): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/priceTarget/${ticker}`, { headers: this.headers });
  }

  // Function to get historical rating for a specific ticker
  histRating(ticker: string): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/histRating/${ticker}`, { headers: this.headers });
  }
  
  retrieveIndexData(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/retrieveIndex`, { headers: this.headers });
  }
}
