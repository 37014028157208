import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { StockDataService } from '../stock-data.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StockListResolver implements Resolve<any> {

  constructor(    private stockDataService: StockDataService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    // Define all stock groups

    // Stock groups data fetch
    const type = route.params['type']; // Get the type from the route params
    if (type === 'gainers' || type === 'losers' || type === 'most') {
      return true
    }

    // Stock groups data fetch
    const data = await this.stockDataService.getStockLists(type).toPromise();
    //const data:any = [];
    
    return data.length > 0 ? data.stockData: false;
  }
}
